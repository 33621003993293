.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-red {
  color: #dc3545;
}

.margin-right-5px {
  margin-right: 5px;
}

.hide{
  display: none;
}

.pad-top-15px {
  padding-top: 15px;
}

span.pointer{
  cursor: pointer;
  color:#666;
}
span.pointer:hover {
  color: #282c34;
}

div.inst-text {
  font-size: 13px;
  padding-bottom: 10px;
  font-style: italic;
  color:#c9270b
}

.footer {
  width: 100%;
  padding:15px;
  text-align: center;
}

.footer div.copy {
  font-size: 13px;
}

.footer div.version {
  font-size: 12px;
}

span.icon{
  font-size: 20px;
  font-weight: bolder;
  margin-right: 3px;
}

span.single-activty{
  display: inline-block;
  padding: 5px;
}

.navbar-sticky {
  position: sticky;
}

span.instance {
  font-size: 11px;
}

.loadingspinner div.modal-content {
  background-color: transparent;
  border: none;
}

div.loadingspinner-text {
  color:#fff;
  font-size: 13px;
  letter-spacing: 1px;
  -webkit-user-select: none;     
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}

span.iconLinkAdd{
  color:#0b47c0;
  cursor: pointer;
  text-transform: uppercase;
}

span.iconLinkAdd:hover{
  color:#012469
}

span.iconLinkRemove{
  color:#fa0339;
  cursor: pointer;
  text-transform: uppercase;
}

span.iconLinkRemove:hover{
  color:#c40710
}

div.show {
  display: block;
}

div.hide {
  display: none;
}

.inline {
  display: inline-block;
}

.warning {
  color: #c40710;
  font-size: 16px;
}

.icon-small {
  font-size: 12px;
}

.remove-red {
  color: #dc3545;
}

.add-blue {
  color: #0b47c0;
}

.select-error {
  border: solid 1px #dc3545;
  border-radius: 5px;
}

div.img-container {
  width:100%;
  position: relative;
  padding-top: 56.25%;
}

@media screen and (max-width: 400px) {
  div.img-container {
    padding-top: 100%;
  }
}

img.img-thumbnail {
  border-radius: 5px;
  position: absolute;
  top: 0; left:0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

tr.clickable {
  cursor: pointer;
}
tr.no_wrap th {
  white-space: nowrap;
  border-top: solid 0px #f6f6f6;
}

tr.no_wrap td {
  white-space: nowrap;
}

.hide{
  display: none !important;
}

span.link {
  color:#012469;
  cursor: pointer;
}